.side-modal {
    bottom: 80px;
    right: -400px;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    width: 0px;
    overflow: hidden;
    position: absolute !important;
    z-index: 11;
    opacity: 0;
    transition: all 0.25s;
    -webkit-transition: all 0.25s;
}

.enter{
    right: 0;
    width: 545px;
    opacity: 1;
}