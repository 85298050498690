/* CarrinhoButton.css */

.button {
    color: #333; /* Cor do texto */
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    overflow: hidden; /* Esconder os efeitos de onda fora do botão */
  }
  
  .button::after {
    content: '';
    position: absolute;
    background: rgba(255, 255, 255, 0.5); /* Cor da onda */
    width: 100px; /* Largura da onda */
    height: 100px; /* Altura da onda */
    border-radius: 50%; /* Formato da onda */
    transition: transform 0.5s, opacity 1s; /* Transições para animar a onda */
    transform: scale(0); /* Iniciar com a onda invisível */
    pointer-events: none; /* Evitar que a onda seja clicada */
  }
  
  .button:hover::after, .button:active::after {
    transform: scale(3); /* Aumentar a escala da onda ao passar o mouse ou clicar */
    opacity: 0; /* Tornar a onda invisível */
  }
  