.cart{
    padding-bottom: 12px;
    padding-top: 11px;
}

@media (max-width: 1366px) {
    .image-custom{
        width: 100%  !important;
    }
    .cart {
     padding: 15px !important;
     justify-content: start;
    }

    .material-phone{
        width: 18.3rem  !important;
    }
    .gap-row{
        gap: 10px;
    }
  }
  