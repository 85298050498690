/* CSS */
.btn {

  box-sizing: border-box;
 
  cursor: pointer;
 

 
  position: relative;
  
  transition: all 150ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;

}


.btn:focus {
  border-color: rgba(0, 0, 0, 0.15);
  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
}

.btn:hover {
  transform: translateY(-0.05rem);
}

.btn:active {
  transform: translateY(0.05rem);
}

.btn:focus {
  box-shadow: none;
}

.btn-secondary,
.btn-success {
  color: #fff;

  &:hover,
  &:focus {
    color: #fff;
  }
}

.btn-gray {
  background-color: rgba($gray-300, .75);
  color: $secondary;
}

.btn-outline-tertiary {
  border-color: $color-ui-02;
  color: $color-ui-02;

  &:hover,
  &:focus {
    background-color: $color-ui-02;
    color: #fff;
  }
}

.btn-outline-secondary:hover,
.btn-outline-secondary:focus {
  color: #fff;
}

.btn-outline-info:hover,
.btn-outline-info:focus {
  color: #fff;
}

.btn-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 0;
  padding-right: 0;
  width: $height-base;
  height: $height-base;
}

.btn-white {
  border: 1px solid $gray-400;
  background-color: #fff;

  &:hover,
  &:focus {
    border-color: darken($gray-400, 5%);
    background-color: $gray-100;
  }
}

.btn-outline-white {
  border: 1px solid rgba(#fff, .75);
  color: rgba(#fff, .75);

  &:hover {
    border-color: #fff;
    color: #fff;
  }
}


$color_1: rgb(120, 50, 5);
$color_2: rgba(120, 50, 5, .8);
$font-family_1: inherit;

.btn-criartgold {
  + {
    .btn-criartgold {
      margin-top: 1em;
    }
  }

  display: inline-block;
  outline: none;
  font-family: $font-family_1;
  font-size: 1em;
  box-sizing: border-box;
  border: none;
  border-radius: .3em;
  line-height: 2.5em;
  text-transform: uppercase;
  padding: 0 1em;
  box-shadow: 0 3px 6px rgba(0, 0, 0, .16),
  0 3px 6px rgba(110, 80, 20, .4),
  inset 0 -2px 5px 1px rgba(139, 66, 8, 1),
  inset 0 -1px 1px 3px rgba(250, 227, 133, 1);
  background-image: linear-gradient(160deg, #a54e07, #b47e11, #fef1a2, #bc881b, #a54e07);
  border: 1px solid #a55d07;
  color: $color_1;
  text-shadow: 0 2px 2px rgba(250, 227, 133, 1);
  cursor: pointer;
  transition: all .2s ease-in-out;
  background-size: 100% 100%;
  background-position: center;

  &:focus {
    background-size: 150% 150%;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23), inset 0 -2px 5px 1px #b17d10, inset 0 -1px 1px 3px rgba(250, 227, 133, 1);
    border: 1px solid rgba(165, 93, 7, .6);
    color: $color_2;
  }

  &:hover {
    background-size: 150% 150%;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23), inset 0 -2px 5px 1px #b17d10, inset 0 -1px 1px 3px rgba(250, 227, 133, 1);
    border: 1px solid rgba(165, 93, 7, .6);
    color: $color_2;
  }

  &:active {
    box-shadow: 0 3px 6px rgba(0, 0, 0, .16), 0 3px 6px rgba(110, 80, 20, .4), inset 0 -2px 5px 1px #b17d10, inset 0 -1px 1px 3px rgba(250, 227, 133, 1);
  }
}