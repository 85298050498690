.custom-hover {
  transition: all 200ms ease-in-out;
}

.custom-hover:hover {
  transform: scale(1.03);
}

.custom-active {
  transition: all 200ms ease-in-out;
}

.custom-overflow {
  max-height: 377px !important;
  overflow-y: auto !important;
}

.w-custom {
  width: 90% !important;
}

.custom-overflow::-webkit-scrollbar {
  width: 2px;
}

.custom-overflow::-webkit-scrollbar-thumb {
  border-radius: 5px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #555;
}

.custom_row {
  transition: all 200ms ease-in-out;
}

.custom_row:hover {
  background-color: #f7f7f7;
}

.custom-card {
  width: 30%;
  max-width: 300px; /* Defina o máximo de pixels conforme necessário */
}

.table-container {
  width: 100%;
}

.table-row {
  display: flex;
}

.table-row.header {
  font-weight: bold;
}

.table-cell {
  flex: 1;
  padding: 8px;
}

.table-cell:first-child {
  flex: 2; /* Ajuste a largura conforme necessário */
}

/* Estilos adicionais conforme necessário */

.span-inter {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
}

.span-inter2 {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
}

.modal-side .modal-dialog {
  width: 300px; /* largura do modal lateral */
  max-width: 80%; /* ajuste a largura conforme necessário */
  margin-left: auto;
  margin-right: 0;
  transform: translateX(100%);
}

.modal-side .modal-content {
  height: 100%;
  overflow-y: auto;
}

.modal-side .modal-header,
.modal-side .modal-footer {
  border: 0;
}

.modal-side .modal-footer {
  justify-content: flex-end;
}

.modal-90w {
  width: 90%;
}


.custom-checkbox-container {
  display: flex;
  align-items: center;
  border: 1px solid black;
}

.custom-checkbox {
  position: relative;
  cursor: pointer;
  padding-left: 25px;
}

.custom-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 15px;
  width: 15px;
  background-color: #eee;
  border: 1px solid #ccc;
}

.custom-checkbox input:checked ~ .checkmark {
  background-color: #2196f3; /* Cor de fundo quando o checkbox está marcado */
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.custom-checkbox input:checked ~ .checkmark:after {
  display: block;
}

.checkmark:after {
  left: 5px;
  top: 1px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}


.detail-overlay {
  position: absolute; /* or absolute, depending on your needs */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it's above other content */
}

.detail-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  /* Any other styling for your detail view */
}